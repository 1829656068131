//todo, use this in employment summary?

export function calculateAverageJobDuration(jobs) {
  if (jobs.length === 0) {
    return '0 months'
  }

  let sum = 0
  jobs.forEach((job) => {
    const startDate = new Date(job.startDate || new Date())
    const endDate = new Date(job.endDate || new Date())
    const months =
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())

    sum += months
  })

  const averageMonths = sum / jobs.length
  if (averageMonths >= 12) {
    return (averageMonths / 12).toFixed(1) + ' years'
  } else if (averageMonths < 1) {
    return '< 1 month'
  } else if (averageMonths === 1) {
    return '1 month'
  } else {
    return averageMonths.toFixed(0) + ' months'
  }
}
