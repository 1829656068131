import { Divider, Modal, Loader, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import TextInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'

import Button from '../../Buttons/Button/Button'

export interface EmailSenderModalProps {
  from: string
  to?: string
  title: string
  subject: string
  body: string
  isOpen: boolean
  loading: boolean
  onClose
  onSend
}

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  subject: z.string().max(100),
  body: z.string().max(2000),
})

const EmailSenderModal = ({
  title,
  subject,
  body,
  isOpen,
  onClose,
  loading,
  onSend,
  to,
}: EmailSenderModalProps) => {
  const form = useForm({
    initialValues: {
      email: to || '',
      subject: subject || '',
      body: body || '',
    },
    validate: zodResolver(schema),
  })

  const onFormSubmit = async (values) => {
    onSend({
      email: values.email,
    })
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size="57%"
      classNames={{ content: 'p-6', title: 'text-lg font-bold' }}
      title={title}
      centered
    >
      <form
        onSubmit={form.onSubmit((values) => onFormSubmit(values))}
        className="z-1 flex w-full flex-col gap-6"
      >
        <div className="flex w-full flex-col gap-4">
          <TextInput
            label="Email"
            className="w-[50%]"
            placeholder="ex. jess@company.com"
            editing={true}
            {...form.getInputProps('email')}
          />
          <TextInput
            label="Subject"
            placeholder="ex. jess@company.com"
            editing={false}
            {...form.getInputProps('subject')}
          />
          <Textarea
            label="Body"
            variant="unstyled"
            classNames={{
              label: 'text-xs font-medium text-doubleNickel-gray-700',
              input: `max-h-[250px] overflow-auto p-6 cursor-pointer caret-transparent
             [&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-600`,
            }}
            autosize
            readOnly
            {...form.getInputProps('body')}
          />
        </div>

        <Divider />

        <div className="flex flex-row gap-3">
          <Button
            className="flex-1"
            text="Cancel"
            variant="outline"
            onClick={onClose}
          />
          <Button
            className="flex-1"
            text="Send"
            variant="filled"
            loading={loading}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  )
}

export default EmailSenderModal
